import styles from './Footer.module.css'
import { FaFacebook, FaRegEnvelope, FaInstagram, FaPhone, FaWhatsapp, FaYoutube } from 'react-icons/fa'
import logo from '../../img/logobranca.png'

function Footer() {
    return (
        <footer>
            <div className={styles.footer}>

                <img className={styles.footerlogo} src={logo} alt='MC Soluções Digitais' />


                <ul className={styles.ulfootercontato}>
                    <li className={styles.lifootercontato}>
                        <p className={styles.lifootercontatonome}>Contato:</p>
                    </li>

                    <li className={styles.lifootercontato1}>
                        <FaPhone className={styles.iconsmedias} />
                        <p className={styles.pfootercontato}> (84) 9 8815 - 4787</p>
                    </li>

                    <li className={styles.lifootercontato}>
                        <FaRegEnvelope className={styles.iconsmedias} />
                        <p className={styles.pfootercontato}> contato@mccomunicacaodigital.com.br</p>
                    </li>
                </ul>

                <p className={styles.textfooter}>Somos uma agência de publicidade com mentes inquietas que nunca se acomodam com o comum. Acreditamos no poder da criatividade para impulsionar marcas e alcançar novos horizontes.</p>

                <div className={styles.containerfootermedia}>

                    <p className={styles.pfootersocialmedia}>Redes Sociais:</p>

                    <ul className={styles.ulfootermedias}>

                    <li className={styles.icons}>
                           <a href="https://www.facebook.com/profile.php?id=61550794089315" target="_blank"> <FaFacebook className={styles.iconsmedias}/> </a>
                        </li>

                        <li className={styles.icons}>
                            <a href="https://www.instagram.com/mccomunicacaodigital/" target="_blank"> <FaInstagram className={styles.iconsmedias} /></a>
                        </li>

                        <li className={styles.icons}>
                         <a href="https://www.youtube.com/@mccomunicacaodigital" target="_blank">   <FaYoutube className={styles.iconsmedias} /></a>
                        </li>

                        <li className={styles.icons}>
                         <a href="https://wa.me/5584988154787?text=Mensagem+MC+Comunica%C3%A7%C3%A3o+Digital%21" target="_blank">   <FaWhatsapp className={styles.iconsmedias} /></a>
                        </li>

                    </ul>

                </div>

            </div>

            <div className={styles.copyright}>
                <span className={styles.spanfooter}>®2023 - MC Comunicação Digital - Todos os direitos reservados.</span>
            </div>

            
        </footer >
    )

}
export default Footer;