import React from "react";
import Serviceshome from "./Serviceshome";
import PropTypes from "prop-types"
import Container from "./Container";
import styles from './Services.module.css'

import socialmedia from './../../img/icons/socialmedia.png'
import graphicdesign from './../../img/icons/graphicdesign.png'
import webdesign from './../../img/icons/webdesign.png'
import videomaker from './../../img/icons/filmaker.png'
import videoeditor from './../../img/icons/videoeditor.png'
import visualidentity from './../../img/icons/visualidentity.png'


function Services() {

    const textsocialmedia = "Trabalhamos a imagem da sua empresa nas redes sociais, com foco no Facebook, Instagram e Youtube. Para cada cliente disponibilizamos planos com números de postagens semanais durante o mês e impulsionamentos, de acordo com a necessidade de cada um." 

    const textgraphicdesign = "Na hora de veicular peças gráficas, seja impressa ou digital, contrate uma equipe profissional qualificada para não passar vergonha. A MC Comunicação Digital produz material gráfico para todas as mídias disponíveis."

    const textwebdesign = 'Na era comunicação digital e em tempo real, podemos afirmar sem sombra de dúvidas que a empresa que não possui sua página na internet com certeza está um passo atrás da concorrência. O site de uma empresa é como o seu escritório como uma janela para o mundo, permitindo alcançar um público global, transcendendo fronteiras geográficas.'

    const textvideomaker = "Arte em movimento, assim podemos definir uma produção de vídeo bem sucedida visualmente e objetivamente. Nesse palco, somos diretores, roteiristas e cinegrafistas, unindo sempre de forma intrínseca a nossa arte, com a mensagem a ser transmitida frame a frame."

    const textvideoeditor = "Tão importante quanto realizar filmagens com boa definição e com ângulos que agradam os telespectadores, o processo de edição de vídeo é a cereja do bolo, é a lapidação de tudo que foi meticulosamente registrado pelas lentes. Com a MC Comunicação Digital, o seu produto ganha vida e se torna capaz de cativar as pessoas, entrando em sintonia com o seu público."

    const textvisualidentity = "Aqui mostramos a essência da sua marca de dentro para fora. Fazemos o trabalho de fio condutor que conecta os colaboradores aos objetivos corporativos, transformando-os em protagonistas da empresa. Com peças gráficas, vídeos motivacionais, campanhas internas e eventos inspiradores, fortalecemos a cultura da empresa, objetivando sempre transformar o local de trabalho em um ambiente profissional de união e amizade."

    return (



        <Container>



            <div className={styles.bgservices}>
                <p className={styles.titleservices}>Nossos Serviços </p>
                <div className={styles.containerservices}>
                    <Serviceshome icon={socialmedia} titlecard={"Gestão de Mídias Sociais"} textcard={textsocialmedia} />
                    <Serviceshome icon={visualidentity} titlecard={"Identidade Visual, Design Corporativo e Endomarketing"} textcard={textvisualidentity} />
                    <Serviceshome icon={webdesign} titlecard={"Desenvolvimento de sites"} textcard={textwebdesign} />
                </div>

                <div className={styles.containerservices2} id={styles.idcontainerservices2}  >
                    <Serviceshome icon={graphicdesign} titlecard={"Design Gráfico"} textcard={textgraphicdesign} />
                    <Serviceshome icon={videomaker} titlecard={"Produção de Vídeo"} textcard={textvideomaker} />
                    <Serviceshome icon={videoeditor} titlecard={"Edição de Vídeo"} textcard={textvideoeditor} />

                </div>

            </div>


        </Container >

    )

}

export default Services
