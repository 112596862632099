import styles from './Why.module.css'
import Container from './Container'
// import Whybg from './/.//../../img/whybg.png'

function Why() {

    return (
        <Container>
            <section className={styles.section}>

                <div className={styles.whycontainer}>

                    <div className={styles.textcontainer}>
                        <p className={styles.title}>Por quê contratar a MC Comunicação  Digital?</p>
                        <p className={styles.text}> A tecnologia e o marketing sempre andaram de mãos dadas durante suas transformações / evoluções. A divulgação de conteúdos publicitários conta com a tecnologia para veicular seus materiais. Dessa maneira, falamos de forma direta e objetiva o quão necessário é estar inserido nos atuais meios de comunicação, independente da plataforma. É aí que a MC Comunicação Digital entra, para cada cliente, definimos uma estratégia para gerar oportunidades e garantir que sua empresa seja vista, criando assim novas possibilidades de alavancar o seu negócio. </p>
                    </div>

                    <div className={styles.imgcontainer}>
                        <p></p>
                    </div>

                </div>

            </section>
        </Container>
    )

}




export default Why