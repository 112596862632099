import styles from './Methodologys.module.css'
import image from './../../img/icons/videoeditor.png'
import React from 'react'
import PropTypes from 'prop-types'


function Methodologys({icon, title, text}) {

    return (

        <div className={styles.divmethodology}>

            <div className={styles.methodology}>

                <div className={styles.divimg}>
                    <img className={styles.img} source src={icon} />
                </div>

                <div className={styles.divtextmethodology}>
                    <p className={styles.methodologytitle}>{title}</p>
                    <p className={styles.methodologytext}> {text} </p>
                </div>

            </div>

        </div>

    )
}

Methodologys.propTypes = {
    icon: PropTypes.string.isRequired,
    titlecard: PropTypes.number,
}

Methodologys.defaultProps = {
    icon: 'Sem imagem',
    title: 'Sem titulo',
    text: 'Sem texto',
}


export default Methodologys