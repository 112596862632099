import React from 'react'
import PropTypes from 'prop-types'
import styles from './Serviceshome.module.css'
import Container from './Container'

function Serviceshome({ icon, titlecard, textcard }) {

    return (

        <>

            <Container>

                <div className={styles.servicescontainer}>

                    <div className={styles.cardcontainer}>

                        <div className={styles.card}>

                            <div className={styles.front}>

                                <img className={styles.iconsimg} source src={icon} />
                                <p className={styles.titlefont}>{titlecard}</p>
                                <p className={styles.click}>Clique para saber mais</p>

                            </div>

                            

                            <div className={styles.back}>

                                <p className={styles.textfont}>{textcard}</p>

                            </div>

                        </div>

                    </div>

                </div>

            </Container>



        </>

    )

    // const pauseworks = document.getElementById()

}

Serviceshome.propTypes = {
    icon: PropTypes.string.isRequired,
    titlecard: PropTypes.number,
}

Serviceshome.defaultProps = {
    icon: 'Sem imagem',
    titlecard: 'Sem texto',
}



export default Serviceshome
