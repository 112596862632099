import styles from './Quemsomos.module.css'
import img1 from './/../layout/iconsmethodology/1.png'
import img2 from './/../layout/iconsmethodology/2.png'
import img3 from './/../layout/iconsmethodology/3.png'
import img4 from './/../layout/iconsmethodology/4.png'
import img5 from './/../layout/iconsmethodology/5.png'

import Methodologys from '../layout/Methodologys'


function Quemsomos() {


    const title1 = "Conhecer o cliente e sua área de atuação"
    const text1 = "Nosso primeiro passo é conhecer a fundo o cliente e a sua área de atuação, mergulhando no universo do negócio em questão e analisando seus concorrentes."

    const title2 = "Criar estratégias pra gerar oportunidades"
    const text2 = "Com as informações coletadas, iniciamos o planejamento estratégico. Aqui o foco é criar oportunidades para alavancar o seu negócio impulsionando resultados."

    const title3 = "Preparação do material"
    const text3 = "Definida a estrategia e escolhida as mídias que serão usadas, colocamos a mão na massa. Iniciamos o processo criativo onde resultará nas peças publicitárias que serão veiculadas."

    const title4 = "Aprovação do material produzido"
    const text4 = "Uma vez desenvolvido o material a ser veiculado, apresentamos o que foi produzido e aguardamos a aprovação/autorização para veicular o que foi feito."

    const title5 = "Veiculação do material produzido"
    const text5 = "Aprovado o material, entramos na última etapa. Organizamos o que será divulgado de acordo com o planejamento e iniciamos a veiculação desse material nas mídias escolhidas."

    return (
        
<section className={styles.section}>
    
        <div className={styles.divmain}>

            <div className={styles.divleft}>
                <div className={styles.leftcontainer}>
                    <p className={styles.titleleft}>Quem Somos?</p>
                  
                    <p className={styles.textleft}> Somos uma agência de publicidade com mentes inquietas que nunca se acomodam com o comum. Acreditamos no poder da criatividade para impulsionar marcas e alcançar novos horizontes. Nossa abordagem é única, mergulhando na essência de cada cliente e trazendo à tona o que o torna especial. Somos contadores de histórias, estrategistas e realizadores de sonhos. Estamos sempre em movimento, transformação, evolução. Como dizia um grande artista nordestino:
                    </p>
                    
                    <p className={styles.textleftchico}>"Que eu me organizando posso desorganizar, que eu desorganizando posso me organizar, que eu me organizando posso desorganizar" (Chico Science) </p>


                    </div>
            </div>

            <div className={styles.divright}> 
            <p className={styles.titlemethodology}>Nossa metodologia de trabalho</p>
                <Methodologys icon={img1} title={title1} text={text1} />
                <Methodologys icon={img2} title={title2} text={text2} />
                <Methodologys icon={img3} title={title3} text={text3} />
                <Methodologys icon={img4} title={title4} text={text4} />
                <Methodologys icon={img5} title={title5} text={text5} />

            </div>

        </div>

</section>


    )
}
export default Quemsomos


