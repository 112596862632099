import styles from './Workshome.module.css'
import atuar1 from './/../../img/workshome/atuar1.jpg'
import dmd1 from './/..//..//img/workshome/dmd1.jpg'
import gfsouto from './/../../img/workshome/gfsouto.png'
import gfsouto1 from './/..//../img/workshome/gfsouto1.jpg'
import gruporoland from './/..//..//img/workshome/gruporoland.jpeg'
import orenda from './/..//..//img/workshome/orenda.jpg'
import politecnica1 from './/..//..//img/workshome/politecnica1.jpeg'
import politecnica2 from './/..//..//img//workshome/politecnica2.jpg'
import Container from './Container'

function Workshome() {

    return (

        <>

            <section>
                
                <div className={styles.title}>
                    <p className={styles.titleworks}>Alguns trabalhos para redes sociais do nosso diretor de arte</p>
                </div>

                <div className={styles.workshome}>

                    <div className={styles.worklist} >

                        <div className={styles.image}>
                            <img className={styles.workimg} src={atuar1} />
                        </div>

                        <div className={styles.image}>
                            <img className={styles.workimg} src={dmd1} />
                        </div>

                        <div className={styles.image}>
                            <img className={styles.workimg} src={gfsouto} />
                        </div>

                        <div className={styles.image}>
                            <img className={styles.workimg} src={gfsouto1} />
                        </div>

                        <div className={styles.image}>
                            <img className={styles.workimg} src={gruporoland} />
                        </div>


                        <div className={styles.image}>
                            <img className={styles.workimg} src={orenda} />
                        </div>

                        <div className={styles.image}>
                            <img className={styles.workimg} src={politecnica1} />
                        </div>

                        <div className={styles.image}>
                            <img className={styles.workimg} src={politecnica2} />
                        </div>

                    </div>

                </div>

            </section >

        </>

    )
}


export default Workshome