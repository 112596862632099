import styles from './Contato.module.css'
import Container from './../layout/Container'
import emailjs from '@emailjs/browser'
import { useState, useRef } from 'react'

function Contato() {


    const [name, setName] = useState('')
    const [mail, setMail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')

    const formRef = useRef(null)

    function sendEmail(e) {

        e.preventDefault();
        if (name === '' || mail === '' || message === '') {
            alert("Por favor, preencha os campos obrigatórios!")

            return;
        }

        const templateParams = {
            name: name,
            mail: mail,
            phone: phone,
            message: message
        }

        emailjs.send("service_x62ngnm", "template_vifjf7r", templateParams, "Jni-h71ehsZI34OVc")
            .then((response) => {
                console.log("Email Enviado", response.status, response.text)
                setName('')
                setMail('')
                setPhone('')
                setMessage('')

            }, (err) => {
                console.log("ERRO: ", err)
            })
        alert("Sua mensagem foi enviada. Obrigado!")
        formRef.current.reset();
    }


    // function handleChange(event) {
    //     const { value, checked } = event.target

    //     if (checked) {
    //         setValue(pre => [...pre, value])
    //     } else (
    //         setValue(pre => {
    //             return [...pre.filter(skill => skill !== value)]
    //         })
    //     )
    // }


    return (

        <Container>

            <div className={styles.contactcontainer}>

                <div className={styles.contact}>

                    <div className={styles.leftcontainer}>

                        <div>

                        </div>
                    </div>

                    <div className={styles.rightcontainer}>
                        <p className={styles.titleform} >Fale com a nossa equipe</p>

                        <form ref={formRef} onSubmit={sendEmail}>

                            <div className={styles.contactformcontainer}>

                                <input type="hidden" name="subject" value="Mensagem de contato do site MC Comunicação Digital" />

                                <label for='name' className={styles.fontcontact}>Nome:</label>
                                <input
                                    className={styles.inputs}
                                    type="text"
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                />

                                <label for='mail' type='email' className={styles.fontcontact}> Email:</label>
                                <input
                                    className={styles.inputs}
                                    type="text"
                                    onChange={(e) => setMail(e.target.value)}
                                    value={mail}
                                />

                                <label for='phone' className={styles.fontcontact}> Telefone(Opcional): </label>
                                <input
                                    className={styles.inputs}
                                    type="text"
                                    onChange={(e) => setPhone(e.target.value)}
                                    value={phone}
                                />  

                                <label for='message' className={styles.fontcontact}>Mensagem:</label>
                                <textarea
                                    className={styles.contactmessage}
                                    onChange={(e) => setMessage(e.target.value)}
                                    value={message}
                                />

                                <div className={styles.buttonscontainer}>

                                    <button className={styles.buttoncontactform} type='submit'>
                                        <p className={styles.buttontext}> Enviar</p>
                                    </button>

                                    <button className={styles.buttoncontactform} type='reset'>
                                        <p className={styles.buttontext}> Limpar</p>
                                    </button>

                                </div>

                            </div>

                        </form>
                    </div>

                </div>

            </div>

        </Container >
    )
}

export default Contato



/*

                        <form ref={formRef} onSubmit={sendEmail}>

                            <div className={styles.contactformcontainer}>

                                <input type="hidden" name="subject" value="Mensagem de contato do site MC Comunicação Digital" />

                                <label for='name' className={styles.fontcontact}>Nome:</label>
                                <input className={styles.inputs} name='name' type='text' id='formname' onChange={(e) => setName(e.target.value)} value={name}> NOME</input>

                                <label for='mail' type='email' className={styles.fontcontact}> Email:</label>
                                <input className={styles.inputs} name='mail' type='email' onChange={(e) => setMail(e.target.value)} value={mail}> </input>

                                <label for='phone' className={styles.fontcontact}> Telefone(Opcional): </label>
                                <input className={styles.inputs} name='phone' type='phone' id='mailphone' onChange={(e) => setPhone(e.target.value)} value={phone}> </input>

                                <label for='message' className={styles.fontcontact}>Mensagem:</label>

                                <textarea name='Message' className={styles.contactmessage} id='Messagetext' onChange={(e) => setMessage(e.target.value)} value={message}>  </textarea>

                                <div className={styles.buttonscontainer}>

                                    <button className={styles.buttoncontactform} type='submit'>
                                        <p className={styles.buttontext}> Enviar</p>
                                    </button>

                                    <button className={styles.buttoncontactform} type='reset'>
                                        <p className={styles.buttontext}> Limpar</p>
                                    </button>

                                </div>

                            </div>

                        </form>
                        */