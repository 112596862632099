import Container from './Container'
import styles from './Quiz.module.css'
import { useState, useRef } from 'react'
import emailjs from '@emailjs/browser'

function Quiz() {

    const [sexo, setSexo] = useState('')
    const [faixaetaria, setFaixaetaria] = useState('')
    const [comosoube, setComosoube] = useState('')
    const [motivo, setMotivo] = useState('')
    const [possuiagencia, setPossuiagencia] = useState('')
    const [checkedValues, setValue] = useState('')

    const formRef = useRef(null)

    function sendEmail(e) {

        e.preventDefault();
        if (sexo === '' || faixaetaria === '' || comosoube === '' || motivo === '' || possuiagencia === '') {
            alert("Por favor, responda todas as perguntas!")
            
            return;
        }
        const templateParams = {
            sexo: sexo,
            faixaetaria: faixaetaria,
            comosoube: comosoube,
            motivo: motivo,
            possuiagencia: possuiagencia,
            checkedValues: checkedValues
        }

        emailjs.send("service_xe7k04r", "template_o85to0v",templateParams, "Jni-h71ehsZI34OVc")
            .then((response) => {
                console.log("Email Enviado", response.status, response.text)
                setSexo('')
                setFaixaetaria('')
                setComosoube('')
                setMotivo('')
                setPossuiagencia('')
                
                
            }, (err) => {
                console.log("ERRO: ", err)
            })
            alert("Suas respostas foram enviadas. Obrigado!")
            formRef.current.reset();
    }

    function handleChange(event) {
        const { value, checked } = event.target

        if (checked) {
            setValue(pre => [...pre, value])
        } else (
            setValue(pre => {
                return [...pre.filter(skill => skill !== value)]
            })
        )
    }





    return (


        <Container>

            <div className={styles.quiz}>

                <form ref={formRef} className="form" onSubmit={sendEmail}>

                    <div className={styles.quizcontainer}>

                        <div className={styles.div1}>
                            <p className={styles.titlediv1}>Ajude-nos a te conhecer. </p>
                            <p className={styles.textdiv1}>Leva menos de um minuto. </p>
                        </div>

                        <div className={styles.div2}>

                            <p className={styles.titlequestions}>Sexo: </p>
                            <div className={styles.questionscontainer}>

                                <select name='sexo' id='sexo' onChange={(e) => setSexo(e.target.value)} value={sexo}>
                                    <option name='sexo' value=""> - </option>
                                    <option name='sexo' value="Masculino">  Masculino </option>
                                    <option name='sexo' value="Feminino">  Feminino </option>
                                    <option name='sexo' value="Não binário">  Não Binário </option>
                                </select>
                            </div>

                            <p className={styles.titlequestions}>Faixa etária: </p>
                            <div className={styles.questionscontainer}>

                                <select name='faixaetaria' id='faixaetaria' onChange={(e) => setFaixaetaria(e.target.value)} value={faixaetaria}>
                                    <option name='faixaetaria' value=""> - </option>
                                    <option name='faixaetaria' value="18-23"> 18-23 </option>
                                    <option name='faixaetaria' value="24-29"> 24-29 </option>
                                    <option name='faixaetaria' value="30-35"> 30-35 </option>
                                    <option name='faixaetaria' value="36-41"> 36-41 </option>
                                    <option name='faixaetaria' value="42-49"> 42-49 </option>
                                    <option name='faixaetaria' value="50+"> 50+ </option>
                                </select>

                            </div>

                            <p className={styles.titlequestions}>Como você ficou sabendo do nosso site? </p>
                            <div className={styles.questionscontainer}>

                                <select name='comosoube' id='comosoube' onChange={(e) => setComosoube(e.target.value)} value={comosoube}>
                                    <option name='comosoube' value=""> - </option>
                                    <option name='comosoube' value="Conhecidos"> Conhecidos/familiares </option>
                                    <option name='comosoube' value="Instagram">  Através do instagram </option>
                                    <option name='comosoube' value="Facebook"> Através do Facebook </option>
                                    <option name='comosoube' value="Youtube"> Através do Youtube </option>
                                    <option name='comosoube' value="Pesquisa"> Sua busca te trouxe até aqui </option>
                                    <option name='comosoube' value="Sebrae"> Através do Sebrae </option>
                                </select>

                            </div>

                            <p className={styles.titlequestions}>O que fez você vir até o nosso site?</p>
                            <div className={styles.questionscontainer}>

                                <select name='motivo' id='motivovisita' onChange={(e) => setMotivo(e.target.value)} value={motivo}>
                                    <option name='motivo' value=""> - </option>
                                    <option name='motivo' value="Curiosidade"> Curiosidade </option>
                                    <option name='motivo' value="Interesse">  Interesse em comunicação digital </option>
                                    <option name='motivo' value="Visibilidade nas redes socias"> Procuro visibilidade nas redes sociais </option>
                                    <option name='motivo' value="Serviços publicitários"> Procuro serviços publicitários </option>
                                    <option name='motivo' value="Analisando opções"> Estudando as opções de empresas de comunicação </option>
  
                                </select>

                            </div>

                            <p className={styles.titlequestions}>Você já faz algum serviço de marketing com outra empresa? </p>
                            <div className={styles.questionscontainer}>

                                <select name='possuiagencia' id='possuiagencia' onChange={(e) => setPossuiagencia(e.target.value)} value={possuiagencia}>
                                    <option name='possuiservico' value=""> - </option>
                                    <option name='possuiservico' value="Não, mas tenho interesse"> Não, mas tenho interesse </option>
                                    <option name='possuiservico' value="Não e não tenho"> Não, e não tenho interesse </option>
                                    <option name='possuiservico' value="Sim, mas não estou satisfeito">  Sim, mas não estou satisfeito  </option>
                                </select>

                            </div>


                            <div className={styles.checkcontainer}>

                                <p className={styles.titlequestions}>Você se interessou por algum dos serviços que oferecemos? Se sim, qual(is)?</p>
                                <input type='checkbox' value="Gestão de midias sociais" onChange={handleChange} />  Gestão de midias sociais
                                <input type='checkbox' value=" Design" onChange={handleChange} /> Design Gráfico
                                <input type='checkbox' value=" Site" onChange={handleChange} /> Desenvolvimento de site
                                <input type='checkbox' value=" Produção de vídeo" onChange={handleChange} /> Produção de Vídeo
                                <input type='checkbox' value=" Edição de vídeo" onChange={handleChange} /> Edição de Vídeo
                                <input type='checkbox' value=" Endomarketing e design corporativo" onChange={handleChange} /> Endomarketing e Design Corporativo

                            </div>


                            <div className={styles.buttonscontainer}>

                                <button className={styles.buttonsend} type='submit'>
                                    <p className={styles.fontbutton}> Enviar</p>
                                </button>

                                <button className={styles.buttonsend} type='reset'>
                                    <p className={styles.fontbutton}> Limpar</p>
                                </button>

                            </div>

                        </div>



                    </div>

                </form>




            </div>

        </Container >



    )
}

export default Quiz
