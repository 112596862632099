import Moviehome from './../../img/MCBANNER.mp4'
import styles from './../layout/Bannerindex.module.css'
import Container from './../layout/Container'
import Logohome from './../../img/../img/LogoFinal.png'

function Bannerindex() {

    return (

        <Container>

            <section className={styles.section}>
                <div className={styles.containermovie}>
                    <video className={styles.moviediv} autoPlay muted loop type="video/mp4">
                        <source className={styles.movie} src={Moviehome} />
                    </video>
                </div>

       
            </section>

        </Container>

    )
}



export default Bannerindex
