// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import {Link} from 'react-router-dom'
import Container from './Container'
import styles from './Navbar.module.css'
import { FaFacebook, FaRegEnvelope, FaInstagram, FaPhone, FaWhatsapp, FaYoutube } from 'react-icons/fa'

function Navbar() {

    return (

        <nav className={styles.navbar}>

            <Container>
                
                <div className={styles.main}>

                    <div className={styles.titlediv}>
                        <p className={styles.titlenavbar}>Sua empresa sendo vista!</p>
                    </div>

                    <ul className={styles.list}>

                        <li className={styles.item}>
                            <Link to="/">Home </Link>
                        </li>

                        <li className={styles.item}>
                            <Link to="/quemsomos">Quem Somos </Link>
                        </li>

                        <li className={styles.item}>
                            <Link to="/miniportfolio"> Trabalhos </Link>
                        </li>
                        <li className={styles.item}>
                            <Link to="/contato">Contato </Link>
                        </li>

                    </ul>

                    <ul className={styles.ulnavbarmedias}>

                        <li className={styles.icons}>
                           <a href="https://www.facebook.com/profile.php?id=61550794089315" target="_blank"> <FaFacebook className={styles.iconsmedias}/> </a>
                        </li>

                        <li className={styles.icons}>
                            <a href="https://www.instagram.com/mccomunicacaodigital/" target="_blank"> <FaInstagram className={styles.iconsmedias} /></a>
                        </li>

                        <li className={styles.icons}>
                         <a href="https://www.youtube.com/@mccomunicacaodigital" target="_blank">   <FaYoutube className={styles.iconsmedias} /></a>
                        </li>

                        <li className={styles.icons}>
                         <a href="https://wa.me/5584988154787?text=Mensagem+MC+Comunica%C3%A7%C3%A3o+Digital%21" target="_blank">   <FaWhatsapp className={styles.iconsmedias} /></a>
                        </li>

                    </ul>

                </div>

            </Container >

        </nav>
    )
}

export default Navbar;