import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import Home from './componentes/pages/Home'
import Miniportfolio from './componentes/pages/Miniportfolio'
import Contato from './componentes/pages/Contato'

import Container from './componentes/layout/Container'
import Navbar from './componentes/layout/Navbar'
import Footer from './componentes/layout/Footer'
import Quemsomos from './componentes/pages/Quemsomos'

function App() {

  return (
    
    <Router>

      <Navbar />

      <Switch>

        <Container customClass="min-height">

          <Route exact path="/">
            <Home />
          </Route>

          <Route exact path="/quemsomos">
            <Quemsomos />
          </Route>

          <Route exact path="/miniportfolio">
            <Miniportfolio />
          </Route>
          <Route exact path="/contato">

            <Contato />
          </Route>

        </Container>

      </Switch>
      

      <Footer />

    </Router>


  )
}

export default App;
