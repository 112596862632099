import styles from './Miniportfolio.module.css'
import img1 from './/..//..//img/works/1.jpg'
import img2 from './/..//..//img/works/2.jpg'
import img3 from './/..//..//img/works/3.jpg'
import img4 from './/..//..//img/works/4.jpg'
import img5 from './/..//..//img/works/5.jpg'
import img6 from './/..//..//img/works/6.jpg'
import img7 from './/..//..//img/works/7.jpg'
import img8 from './/..//..//img/works/8.jpg'
import img9 from './/..//..//img/works/9.jpg'
import J1 from './/..//..//img/works/J1.jpg'
import J2 from './/..//..//img/works/J2.jpg'


import s1 from './/..//..//img/works/s1.jpg'
import s2 from './/..//..//img/works/s2.jpg'
import s3 from './/..//..//img/works/s3.jpg'
import s4 from './/..//..//img/works/s4.jpg'
import site1 from './/..//..//img/works/site1.jpg'
import site2 from './/..//..//img/works/site2.jpg'
import site3 from './/..//..//img/works/site3.jpg'

function Miniportfolio() {

    return (
        <>

            <p className={styles.title}> Mais alguns trabalhos do nosso diretor de arte  </p>

            <div className={styles.divmainworks}>


                <div className={styles.divworks}>

                    <img className={styles.imgfeed} src={img1} />
                    <img className={styles.imgfeed} src={img2} />
                    <img className={styles.imgfeed} src={img3} />
                    <img className={styles.imgfeed} src={img4} />
                    <img className={styles.imgfeed} src={img5} />
                    <img className={styles.imgfeed} src={img6} />
                    <img className={styles.imgfeed} src={img7} />
                    <img className={styles.imgfeed} src={img8} />
                    <img className={styles.imgfeed} src={img9} />
                    <img className={styles.imgfeed} src={J1} />
                    <img className={styles.imgfeed} src={J2} />

                </div>

                <div className={styles.divworks}>

                    <img className={styles.imgstories} src={s1} />
                    <img className={styles.imgstories} src={s2} />
                    <img className={styles.imgstories} src={s3} />
                    <img className={styles.imgstories} src={s4} />


                </div>

                <div className={styles.divworks}>

                <p className={styles.titlemovie}>Alguns vídeos editados pela MC Comunicação Digital</p>

                    <iframe className={styles.videos} width="560" height="315" src="https://youtube.com/embed/nfpwwrKhEbY?si=cgFiU4we8wIkdV2K" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                    <iframe className={styles.videos} width="560" height="315" src="https://youtube.com/embed/5koT22X5Vrg?si=606FmThYriCimoXx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                    <iframe className={styles.videos} width="560" height="315" src="https://youtube.com/embed/ZUO25kwyf2Q?si=i-vKhzQA7JXXFVmB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                    <iframe className={styles.videos} width="560" height="315" src="https://youtube.com/embed/tupCmck8yYY?si=5Mc4nl4eR2CvT1pe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                    <iframe className={styles.videos} width="560" height="315" src="https://youtube.com/embed/RiSqdRTKunk?si=sJva-0KR0qiPHhYh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                    <iframe className={styles.videos} width="560" height="315" src="https://youtube.com/embed/hBG5686xW6A?si=eUHE9DVcG-qTyeUm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

{/* 

                    SUBSTITUIR SHOTS POR EMBED
                    <iframe className={styles.videopt} width="560" height="315" src="https://www.youtube.com/embed/x5KG0jkzVYw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}

                </div>

                <div className={styles.divworks}>
                    <div className={styles.divsites}>
                        <p className={styles.title}>Alguns sites desenvolvidos pela MC Comunicação Digital</p>
                        <img className={styles.imgsites} src={site1} />
                        <img className={styles.imgsites} src={site2} />
                        <img className={styles.imgsites} src={site3} />

                    </div>
                </div>

            </div>

        </>

    )
}

export default Miniportfolio