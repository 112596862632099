import styles from './Home.module.css'
import Bannerindex from './/../layout/Bannerindex'
import Services from '../layout/Services'
import Why from '../layout/Why'
import Workshome from '../layout/Workshome'
import Quiz from '../layout/Quiz'

function Home() {

    return (

        <>
        
            <section className={styles.section}>
                <Bannerindex />
               
            </section>
            <Why />    
            <Services />
            <Workshome />
            <Quiz />

            
        </>
    )
}

export default Home